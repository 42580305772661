import Vue from 'vue';
import FeatureIntro from '../lib/components/Holocron/FeatureIntro';
import FeatureIntroRevealer from '../lib/components/Holocron/FeatureIntroRevealer';

function initFeatureIntro(store) {
  const introElem = document.getElementById('feature-intro');
  if (introElem) {
    new Vue({
      el: introElem,
      name: 'FeatureIntroApp',
      components: {
        'feature-intro': FeatureIntro
      },
      store
    });
  }
}

function initFeatureIntroRevealer(store) {
  setTimeout(() => {
    const revealerElements = document.getElementsByTagName(
      'feature-intro-revealer'
    );
    if (revealerElements.length) {
      new Vue({
        el: revealerElements[0],
        name: 'FeatureIntroRevealerApp',
        components: {
          'feature-intro-revealer': FeatureIntroRevealer
        },
        store
      });
    }
  }, 100);
}

export default function initHolocron(store) {
  if (store === undefined) {
    store = require('../lib/store/index').default;
  }

  initFeatureIntro(store);
  initFeatureIntroRevealer(store);
}
