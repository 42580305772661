import { TMediaError } from '@/domain/services/VideoPlayer/VideoPlayerWrapper';
import { PostingEndpoint, PuttingEndpoint } from '@/domain/services/Api/Endpoint';

const putLog = (url: string) => PuttingEndpoint(url);
const postLogError = (url: string) => PostingEndpoint(url);

const useVideoPlayerApi = {
	putLog: async (fileId: string, data: { watched: number }) => putLog('/video-log/' + fileId + '/log')(data),
	postLogError: (fileId: string, errorData: TMediaError) => postLogError('/video-log/' + fileId + '/error')()(errorData),
};

export { useVideoPlayerApi };
