<template>
  <currency-input
    :id="getId()"
    v-model="inputValue"
    class="form-control"
    :currency="field.currency"
    :locale="currentLocale"
    :distraction-free="false"
    :placeholder="placeHolder"
    :disabled="disabled"
    :aria-invalid="hasError"
    :aria-describedby="ariaDescribedby"
    :aria-required="field.required ? 'true' : 'false'"
    @input="onInput(inputValue)"
    @focusout="onFocusOut"
  />
</template>

<script>
import Field from './Field.vue';
import { CurrencyInput } from 'vue-currency-input';

export default {
  inject: ['lang'],
  components: { CurrencyInput },
  extends: Field,
  data() {
    return {
      inputValue: this.calculateValue(this.value),
      currentLocale: this.lang.locale.replace('_', '-')
    };
  },
  computed: {
    placeHolder() {
      if (this.field.currency) {
        return Number(0)
          .toLocaleString(this.currentLocale, {
            style: 'currency',
            currency: this.field.currency,
            minimumFractionDigits: 0
          })
          .replace('0', '');
      }
      return null;
    }
  },
  methods: {
    onFocusOut() {
      this.inputValue = this.calculateValue(this.inputValue);
    },
    calculateValue(value) {
      return value !== '' && value !== null ? Number(value) : null;
    }
  }
};
</script>
