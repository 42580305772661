import $ from 'jquery';
import Vue from 'vue';

const mountVueWithComponents = function (tag, component, components, withStore = true, destroy = true) {
	const elements = [...document.getElementsByTagName(tag)];
	const instanceName = (i) =>
		`MountVue${tag.replace(/-./g, (x) => x[1].toUpperCase()).replace(/^./, (x) => x.toUpperCase())}${i}App`;

	if (elements.length !== 0) {
		const store = require('@/lib/store/index').default;
		elements.forEach((el, i) => {
			const vueInstance = new Vue({
				el,
				name: instanceName(i),
				components: Object.assign(components, { [tag]: component.default }),
				store: withStore ? store : null,
			});

			if (destroy) {
				$(document).one('pjax:end', () => {
					vueInstance.$destroy();
				});
			}
		});
	}
};

const mountVueComponent = function (tag, component, withStore = true, destroy = true) {
	mountVueWithComponents(tag, component, {}, withStore, destroy);
};

export { mountVueComponent, mountVueWithComponents };
