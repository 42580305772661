/**
 * This is the main module of the application and defines the global dependencies,
 * sets up application-wide client-side features and functionality (such as the PJAX
 * implementation, the spinner.etc.) and also setups the required routing.
 */

// following line has to be THE FIRST LINE EXECUTED
// eslint-disable-next-line sort-imports
import '@/bootstrap';

import Vue from 'vue';

import { Composer } from '@/domain/services/Composer';
import { Init as PjaxInit } from '@/legacy/pjax-module.js';

import initHolocron from './common/holocron.js';
import initSixDigitCode from './common/six-digit-code.js';

// eslint-disable-next-line no-undef, camelcase
__webpack_public_path__ = window.__webpack_public_path__ || '/';

require('babel-polyfill');
require('./lib/jquery/security.js');
require('./lib/jquery/jquery-contains.js');
require('./vendor/pjax/jquery.pjax.js');
require('./lib/select2.js');
require('./vendor/underscore/underscore.js');
require('./vendor/colpick/colpick.js');
require('bootstrap');
require('./vendor/intl-numbers/intlTelInput.js');
require('./lib/filtertron/filtertron.js');
require('./lib/filtertron/filtertron-tray.js');
require('./lib/clarify.js');
require('./lib/moment.js');
require('@/vendor/stickybits/jquery.stickybits.js');

var darkmode = require('./common/darkmode');
var $ = require('jquery');
var tabular = require('tabular');

Vue.config.ignoredElements = ['zapier-app-directory'];
Vue.config.productionTip = false;

$(document).on('pjax:complete', () => Composer.loadModule());

$(document).on('ready', async () => {
	await Composer.loadModule();

	darkmode.init();

	require('./lib/tabbed-content.js');
	require('./lib/pjax.js');
	require('./lib/main-menu.js');
	require('./lib/context-menu.js');
	require('./lib/brand-footer.js');
	require('./lib/redirector.js');
	require('./lib/status-widget.js');
	require('./lib/tooltip.js');
	require('./lib/account-switcher.js');
	require('./common/global.js');
	require('./common/season-selector.js');
	require('./common/form-selector.js');
	require('./common/trash-selector.js');
	require('./common/pagination-selector.js');
	require('./common/multiselect.js');
	require('./common/pusher.js');
	require('./common/link-poster.js');
	require('./common/simple-modal.js');
	require('./common/strip.js').init();
	require('./common/accessibility.js');
	require('./common/cookie-notice.js');
	require('./modules/modules.js');

	PjaxInit();
	tabular();

	require('./common/alert-validation.js').init();
	require('./modules/interface-text/interface-text-popovers.js').default.init();
	initHolocron();
	initSixDigitCode();
});
