// eslint-disable-next-line import/exports-last
export const defaultState = () => ({
	user: null,
	isManager: false,
	hasManagerRole: false,
	title: null,
	season: null,
	chapterId: null,
	selectedCategoryId: null,
	loadedChapters: [],
	loadedTabFields: [],
	tabId: null,
	entry: null,
	tabs: [],
	fields: [],
	contributors: {},
	attachments: null,
	updatedAt: null,
	values: {
		Attachments: {},
		Entries: {},
		Contributors: {},
	},
	conditionalVisibility: {
		Attachments: {},
		Entries: {},
		Contributors: {},
	},
	status: { status: 'ok', message: '' },
	chapters: [],
	tabsLoaded: false,
	tabFieldsLoading: {},
	chaptersLoaded: false,
	attachmentsLoaded: false,
	contributorsLoaded: false,
	uploaderStatus: {},
	uploadInProgress: false,
	locks: {
		lockedCategory: false,
		readOnly: false,
		ineligible: false,
		lockedChapter: false,
		previewMode: false,
	},
	sponsorsEnabled: false,
	links: {},
	formHash: null,
	errors: {},
	tabFieldsErrors: {},
	tabApiErrors: [],
	tempFiles: [],
	fieldGuideUrl: null,
	categories: [],
	plagiarismDetection: false,
	searchableFields: [],
	fieldTypes: [],
	fieldCompatibility: {},
	conditionalFieldTypes: [],
	conditionals: {},
	protectionTypes: [],
	protectionGuideUrl: null,
	fieldTemplate: {},
	fileTypes: {},
	tableFieldConfiguratorLabels: {},
	tableFieldInputTypes: [],
	tabTemplate: {},
	tabTypes: [],
	contentBlocks: [],
	categoryTemplate: {},
	categoryMergeFields: [],
	scoreSets: [],
	attachmentTypes: {},
	chapterManagers: [],
	chapterTemplate: {},
	isAtChapterLimit: false,
	canConfigureCategories: false,
	canConfigureChapters: false,
	chapterVisible: false,
	awaitingPayment: false,
	allowsDownloadBlankPdf: false,
	formSessionUuid: null,
	form: null,
	formTypes: null,
	formEdited: false,
	visibleSelectors: true,
	visibleTitle: true,
	formId: null,
	eligibleContentBlocks: [],
	ineligibleContentBlocks: [],
	eligibleNotifications: [],
	ineligibleNotifications: [],
	eligibilityContentBlock: null,
	multiChapter: true,
	multiForm: true,
});

const state = defaultState();

export default state;
