import DOMPurify from 'dompurify';
import marked from 'marked';

require('./clarify.js');

const isIE9OrOlder = function () {
	return !!window.document.documentMode;
};

const truncate = function (string, length, ellipsis) {
	ellipsis = ellipsis || '...';
	length = ~~length;

	return string.length > length ? string.slice(0, length) + ellipsis : string;
};

const sanitize = function (string, options = {}) {
	return DOMPurify.sanitize(string, {
		...{ USE_PROFILES: { html: true } },
		...options,
	});
};

const route = function (uri, variables = {}) {
	if (typeof variables !== 'object' && !Array.isArray(variables)) {
		variables = [variables];
	}

	const url = (
		'/' +
		uri.replace(
			new RegExp('{([^{]+)??}', 'g'),
			// eslint-disable-next-line  @typescript-eslint/naming-convention
			(_, name) => variables[name.replace('?', '')] || ''
		)
	).replace(/\/*$/, '');

	const data = { ...variables };
	[...uri.matchAll(/\{([^{]+)\??\}/g)].forEach((el) => delete data[el[1].replace('?', '')]);
	const query = Object.keys(data)
		.map((key) => key + '=' + data[key])
		.join('&');

	if (query === '') {
		return url;
	}

	return url + (url.split('?')[1] ? '&' : '?') + query;
};

const validSubdomainString = function (text) {
	return text
		.toLowerCase()
		.trim()
		.replace(/grants|awards|grant|award/g, '')
		.replace(/\s+/g, '')
		.replace(/[^\p{L}]+/gu, '');
};

const getGlobal = function (variable) {
	return window.App && window.App[variable] !== undefined ? window.App[variable] : null;
};

const getGlobalData = function (key) {
	try {
		const globalData = getGlobal('obfuscatedData').clarify();
		if (globalData[key] === undefined) {
			console.error(`No ${key} registered in VueData`);
		} else {
			return globalData[key];
		}
	} catch (e) {
		if (getGlobal('obfuscatedData') != null) {
			console.warn(e);
		}
	}

	return [];
};

const supportedLanguages = () => getGlobal('supportedLanguages')?.clarify();

const parseInteger = function (value) {
	const integer = parseInt(value);
	return !isNaN(integer) ? integer : null;
};

const isFacebookBrowser = function () {
	const ua = navigator.userAgent || navigator.vendor || window.opera;
	return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1 || ua.indexOf('Instagram') > -1;
};

const stripMarkdown = function (string, options = {}) {
	return DOMPurify.sanitize(marked(string), {
		...{ USE_PROFILES: { html: false } },
		...options,
	}).trim();
};

const isObject = (obj) => obj === Object(obj) && !(obj instanceof Function);

function flattenObject(a, parentKey = null, result = []) {
	for (const key of Object.keys(a)) {
		let resultKey = parentKey ? `${parentKey}[${key}]` : `[${key}]`;
		result = isObject(a[key])
			? { ...result, ...flattenObject(a[key], resultKey, result) }
			: { ...result, [resultKey]: a[key] };
	}

	return result;
}

const reactiveInjects = (getters) => {
	const reactiveInjects = {};

	for (const getterName in getters)
		Object.defineProperty(reactiveInjects, getterName, {
			enumerable: true,
			get: getters[getterName],
		});

	return { reactiveInjects };
};

const withReactivity = (injects) => [...injects, 'reactiveInjects'];

const reactiveInject = (context, inject) => context.reactiveInjects[inject];

const sanitizeObject = (obj) => JSON.parse(JSON.stringify(obj));

function getSubmittableUrl(submittable) {
	return submittable === 'grant-report' ? 'grant-report' : 'entry';
}

const windowLocation = {
	getHref: () => window.location.href,
	setHref: (location) => (window.location.href = location),
	getPathName: () => window.location.pathname,
	setPathName: (path) => (window.location.pathname = path),
};

export {
	isIE9OrOlder,
	truncate,
	sanitize,
	route,
	validSubdomainString,
	getGlobal,
	getGlobalData,
	supportedLanguages,
	parseInteger,
	isFacebookBrowser,
	stripMarkdown,
	isObject,
	flattenObject,
	reactiveInjects,
	withReactivity,
	reactiveInject,
	sanitizeObject,
	getSubmittableUrl,
	windowLocation,
};
