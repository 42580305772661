<script>
import DraggableColumns from './DraggableColumns.vue';

export default {
	components: {
		DraggableColumns,
	},
	props: {
		area: {
			type: String,
			required: true,
		},
		columns: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			selectedArea: this.area,
		};
	},
	computed: {
		availableColumns() {
			const columns = this.findColumns(this.selectedArea);
			return columns ? columns.allColumns : [];
		},
		selectedColumns() {
			const columns = this.findColumns(this.selectedArea);

			return (columns ? columns.defaultColumns : [])
				.map((name) => this.availableColumns.find((column) => column.name === name))
				.filter((column) => column)
				.sort((a, b) => a.title.localeCompare(b.title))
				.map((column) => column.name);
		},
	},
	watch: {
		selectedArea() {
			this.updateQueryString('area', this.selectedArea);
		},
	},
	methods: {
		findColumns(area) {
			return this.columns.find((c) => c.area === area);
		},
		updateQueryString(key, value) {
			const queryParams = new URLSearchParams(window.location.search);

			if (queryParams.get(key) === value) {
				return;
			}

			queryParams.set(key, value);

			const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
			window.history.pushState({}, '', newUrl);

			window.location.reload();
		},
	},
};
</script>
