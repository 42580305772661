const { mountVueComponent } = require('@/lib/mount-vue-component.js');

var $ = require('jquery');
/* eslint-disable @typescript-eslint/naming-convention */
var Audio = require('@/lib/audio.js');
var Cards = require('@/lib/cards.js');
require('@/vendor/stickybits/jquery.stickybits.js');
require('tectoastr');
var VideoPlayer = require('@/domain/services/VideoPlayer/VideoPlayer').default;
var VideoModal = require('@/domain/services/VideoPlayer/VideoPlayerModal').default;
var VideoThumbnails = require('@/lib/video-thumbnails.js');
var isMobile = require('@/lib/navigation-is-mobile.js');
var store = require('@/lib/store/index.js').default;

const Vue = require('vue');
const Preferences = require('./Preferences/Preferences.vue').default;
/* eslint-enable @typescript-eslint/naming-convention */

module.exports = function () {
	/**
	 * Cards
	 */
	var cards = new Cards();
	cards.setup('ul.cards', 'li', 'fitRows');

	/**
	 * Audio
	 */
	var audio = new Audio();
	audio.setup('jp-jplayer');

	/**
	 * Video
	 */
	var videoThumbnails = new VideoThumbnails();

	VideoPlayer.setup('video-js-standalone');
	VideoModal.setup('play-video');
	videoThumbnails.setup('preview-container.video');

	const rows = document.querySelectorAll('.preferences');

	[].forEach.call(rows, (el) => {
		new Vue({
			el: el,
			name: 'PreferencePickApp',
			components: {
				preferences: Preferences,
			},
			store,
		});
	});

	if (!isMobile()) {
		$('.header-controls').stickybits({
			useStickyClasses: true,
		});
	}

	/** Comments */
	import('@/lib/components/Comments/Comments.vue').then((comments) => {
		mountVueComponent('comments', comments);
	});
};
