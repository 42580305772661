<template>
	<div :class="{ cards: !attachmentMode, clearfix: !attachmentMode }">
		<transition-group name="push" tag="div" class="file-uploads-transition">
			<div v-for="file in uploadedFiles" :key="file.id">
				<component
					:is="fileView"
					:v-if="uploader"
					:uploader="uploader"
					:attachment="attachments ? attachments.find((a) => a.file.id === file.id) : null"
					:can-delete="canDelete"
					:file="file"
					:num-of-attachments="Object.keys(attachments).length"
					:preview-types="options.previewTypes"
					:disabled="disabled"
					:caption-mode="captionMode"
					:field-required="fieldRequired"
					@canceled="cancelUpload"
					@deleted="deletedFile"
					@replaced="replacedFile"
					@retry="retryUpload"
					@browse="browse"
					@retried="retryTranscoding"
					@movedLeft="$emit('movedLeft', $event)"
					@movedRight="$emit('movedRight', $event)"
					@imageDataUrl="$emit('imageDataUrl', $event, file)"
				>
					<uploader
						v-if="!captionMode && showCaptionUploader(file)"
						:id="file.id"
						:options="options"
						:can-delete="canDelete"
						:files="file.caption ? [file.caption] : []"
						:parent-file="file"
						:disabled="disabled"
						:caption-mode="true"
						v-on="$listeners"
					>
					</uploader>
					<template slot="file-extra-info">
						<slot name="file-extra-info" />
					</template>
				</component>
			</div>
		</transition-group>

		<div
			v-if="!captionMode"
			v-show="canUpload && (uploadedFiles.length === 0 || attachmentMode || multiSelection)"
			ref="dnd-element"
			class="dnd-container"
			:class="{
				card: attachmentMode || multiSelection,
				'action-card': attachmentMode || multiSelection || border,
				'full-width': border,
				'text-center': !attachmentMode && !multiSelection,
				error: showErrors,
			}"
			:draggable="!disabled"
		>
			<div v-show="!disabled" class="dnd-overlay" @dragenter="dragEnter" @dragleave="dragLeave" @drop="dragDrop"></div>
			<div v-show="!fileLimitReached && !captionMode" class="dnd">
				<div class="dnd-icon">
					<i class="af-icons af-icons-drag-n-drop-uploader"></i>
				</div>
				<div class="dnd-text">
					<span>{{ lang.choice('files.buttons.drag_and_drop', attachmentMode ? 2 : 1) }}</span>
				</div>
				<div class="dnd-text">
					<span>{{ lang.get('miscellaneous.search.or') }}</span>
				</div>

				<button
					v-if="showCancelAction"
					type="button"
					class="cancel-button btn btn-tertiary"
					@click="$emit('replaceCanceled')"
				>
					{{ lang.get('files.actions.cancel') }}
				</button>
				<button
					ref="button"
					type="button"
					:class="['upload-button', 'btn', 'btn-secondary']"
					:disabled="disabled || fileLimitReached"
				>
					<slot name="btn-upload-text">
						<translation :text="buttonLabel ? buttonLabel : lang.get('files.buttons.single')" />
					</slot>
				</button>
			</div>
			<p v-if="fileLimitReached" class="file-limit-reached">
				<slot name="file-limit-reached-text" />
			</p>
		</div>
		<div v-show="showCaptionButton" class="action-card caption-uploader" style="width: 100%">
			<div class="text-left">
				{{ lang.get('files.captions.upload') }}
				{{ lang.get('miscellaneous.optional') }}
			</div>
			<div class="text-left">
				<a :href="applicationLinks.get('captions')" target="_blank" rel="noopener noreferrer">
					{{ lang.get('files.captions.help') }}
				</a>
			</div>
			<div class="ptm text-left">
				<button ref="captionButton" type="button" :class="['action-button', 'caption-uploader-button']">
					{{ lang.get('files.buttons.single') }}
				</button>
			</div>
		</div>
		<validation-errors v-if="showErrors" :validation-errors="fileErrors"></validation-errors>
	</div>
</template>

<script>
import $ from 'jquery';
import plupload from 'plupload';
import tectoastr from 'tectoastr';
import { featureEnabled } from '@/services/global/features.interface';
import Status from './Status.js';
import ProcessingStatus from './ProcessingStatus.js';
import minFileSize from './filters/minFileSize.js';
import minVideoLength from './filters/minVideoLength.js';
import maxVideoLength from './filters/maxVideoLength.js';
import FileUpload from './FileUpload';
import { mapGetters } from 'vuex';
import HelpIcon from '../Shared/HelpIcon';
import Translation from '@/modules/interface-text/components/Translation';
import { isFacebookBrowser } from '@/lib/utils.js';
import ValidationErrors from '@/lib/components/Fields/ValidationErrors.vue';

plupload.addFileFilter('min_file_size', minFileSize);
plupload.addFileFilter('min_video_length', minVideoLength);
plupload.addFileFilter('max_video_length', maxVideoLength);

export default {
	name: 'Uploader',
	inject: ['lang', 'featuresService', 'applicationLinks'],
	components: {
		ValidationErrors,
		HelpIcon,
		FileUpload,
		Translation,
	},

	props: {
		options: {
			type: Object,
			default: () => {},
			validator: (options) => Object.prototype.hasOwnProperty.call(options, 's3'),
		},
		captionMode: {
			type: Boolean,
			default: false,
		},
		border: {
			type: Boolean,
			default: false,
		},
		parentFile: {
			type: Object,
			default: () => {},
		},
		attachmentMode: {
			type: Boolean,
			default: false,
		},
		attachments: {
			type: Array,
			default: () => [],
		},
		canDelete: {
			type: Boolean,
			default: true,
		},
		canUpload: {
			type: Boolean,
			default: true,
		},
		files: {
			type: Array,
			default: () => [],
		},
		fileLimit: {
			type: Number,
			default: null,
		},
		fileCount: {
			type: Number,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		buttonLabel: {
			type: String,
			default: null,
		},
		fieldRequired: {
			type: Boolean,
			default: false,
		},
		language: {
			type: String,
			default: null,
		},
		inActiveTab: {
			type: Boolean,
			default: true,
		},
		minVideoLength: {
			type: [Number, null],
			default: null,
		},
		maxVideoLength: {
			type: [Number, null],
			default: null,
		},
		handleErrors: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			isUploading: false,
			uploadedFiles: [],
			multiSelection: false,
			filesAdded: 0,
			filesProcessed: 0,
			interval: {},
			uploader: null,
			fileView: FileUpload,
			fileErrors: [],
		};
	},

	computed: {
		completeFileTokens() {
			const tokens = this.uploadedFiles.filter((file) => file.status === Status.COMPLETED).map((file) => file.token);

			if (tokens.length === 0) {
				return '';
			} else if (tokens.length === 1) {
				return tokens[0];
			} else {
				return JSON.stringify(tokens);
			}
		},
		fileLimitReached() {
			return this.fileLimit && this.fileLimit <= this.fileCount;
		},
		tabIsHidden() {
			return this.tab && this.tabIsVisible(this.tab);
		},
		showCaptionButton() {
			return (
				this.parentFile &&
				this.showCaptionUploader(this.parentFile) &&
				Number(this.parentFile.remoteId) > 0 &&
				this.parentFile.status === Status.COMPLETED &&
				this.captionMode &&
				this.uploadedFiles.length === 0
			);
		},
		showCancelAction() {
			return this.fieldRequired && this.files.length && this.fileLimit === 1;
		},
		showErrors() {
			return this.handleErrors && !!this.fileErrors.length;
		},
		...mapGetters('entryForm', ['tabIsVisible']),
	},

	watch: {
		/**
		 * In attachment mode sort files by order as in attachments.
		 */
		files() {
			if (this.uploadedFiles.length === 0) {
				this.loadExistingFiles();
			}

			if (this.attachmentMode) {
				this.uploadedFiles.sort((uf1, uf2) => {
					const a1 = this.attachments.find((a) => a.file.id === uf1.id);
					const a2 = this.attachments.find((a) => a.file.id === uf2.id);
					return (a1 ? a1.order : 0) - (a2 ? a2.order : 0);
				});
			} else if (!this.captionMode) {
				this.uploadedFiles.forEach((file) => {
					if (
						!this.files.some(
							(f) => f.status !== Status.COMPLETED || f.id === undefined || f.id === file.id || f.id === file.remoteId
						)
					) {
						this.cancelUpload(file);
					}
				});
			}
		},
		inActiveTab(isOnActiveTab, wasOnActiveTab) {
			if (!wasOnActiveTab && isOnActiveTab && !this.uploader) {
				this.mountUploader();
			}
		},
	},

	mounted() {
		if (this.options && this.inActiveTab && !this.uploader) {
			this.mountUploader();
		}
	},

	beforeDestroy() {
		$(document).off('file.processed', this.onFileProcessed);
		$(document).off('file.transcoding', this.onFileTranscoding);
		// Skip destroying in caption mode, the parent uploader is already destroyed and there is no runtime
		if (this.uploader && !this.captionMode) {
			try {
				if (this.isUploading) {
					this.uploadedFiles.forEach((file) => this.cancelUpload(file));
				}

				this.uploader.unbindAll();
				this.uploader.destroy();
			} catch (e) {
				this.uploader = null;
			}
		}
	},

	methods: {
		mountUploader() {
			this.loadExistingFiles();

			this.initPlupload();

			// Handle Pusher events
			$(document).on('file.processed', this.onFileProcessed);
			$(document).on('file.transcoding', this.onFileTranscoding);
		},
		showCaptionUploader(file) {
			return (
				featureEnabled('transcoding') &&
				this.options.previewTypes &&
				file.original !== undefined &&
				this.options.previewTypes.video.indexOf(file.original.split('.').pop().toLowerCase()) !== -1
			);
		},
		/**
		 * Load existing files passed in via the 'files' prop.
		 */
		loadExistingFiles() {
			this.files.forEach((file) => {
				if (!this.uploadedFiles.some((uploadedFile) => uploadedFile.remoteId === file.id)) {
					const status =
						file.status === ProcessingStatus.OK || file.status === Status.COMPLETED ? Status.COMPLETED : Status.FAILED;

					this.uploadedFiles.push(
						Object.assign({}, file, {
							loaded: file.size,
							image: file.image,
							name: null,
							percent: 100,
							remoteId: file.id,
							status: status,
							url: file.url,
						})
					);
				}
			});
		},

		/**
		 * Initialise Plupload.
		 */
		initPlupload() {
			const uploaderOptions = JSON.parse(JSON.stringify(this.options.s3));

			uploaderOptions['browse_button'] = this.$refs[!this.captionMode ? 'button' : 'captionButton'];

			if (this.captionMode) {
				uploaderOptions['filters'] = {
					mime_types: [{ title: 'Caption files', extensions: 'vtt' }],
				};
			} else if (!this.disabled) {
				uploaderOptions['drop_element'] = this.$refs['dnd-element'];
			}

			if (this.minVideoLength > 0) {
				uploaderOptions['filters'] = {
					...uploaderOptions['filters'],
					min_video_length: this.minVideoLength,
				};
			}

			if (this.maxVideoLength > 0) {
				uploaderOptions['filters'] = {
					...uploaderOptions['filters'],
					max_video_length: this.maxVideoLength,
				};
			}

			this.uploader = new plupload.Uploader(uploaderOptions);

			this.multiSelection = uploaderOptions['multi_selection'] || false;

			this.uploader.init();

			this.uploader.bind('FilesAdded', this.onFilesAdded);
			this.uploader.bind('BeforeUpload', this.onBeforeUpload);
			this.uploader.bind('BeforeChunkUpload', this.onBeforeChunkUpload);
			this.uploader.bind('UploadProgress', this.onUploadProgress);
			this.uploader.bind('FileUploaded', this.onFileUploaded);
			this.uploader.bind('Error', this.onError);
			this.uploader.bind('PostInit', this.onPostInit);
			this.uploader.bind('Browse', this.onBrowse);
		},

		/**
		 * Step 1: Files added
		 *
		 * Once a user selects files to be uploaded an array that is used to track uploads
		 * can be populated with file records.
		 *
		 * Initial status of all files is set to QUEUED.
		 */
		onFilesAdded(uploader, files) {
			if (this.fileLimit && files.length + this.uploadedFiles.length > this.fileLimit) {
				if (!this.multiSelection) {
					files
						.slice(this.fileLimit - this.uploadedFiles.length)
						.map((f) => f.id)
						.forEach((id) => uploader.removeFile(id));
					files = uploader.files;
					tectoastr.error(this.lang.get('files.messages.single_file_limit'));
				} else {
					uploader.splice();
					tectoastr.error(this.lang.get('files.messages.attachment_file_limit'));

					return false;
				}
			}

			files.forEach((file) => {
				const newFile = {
					foreignId: !this.captionMode ? this.options['foreignId'] : this.parentFile.remoteId,
					id: file.id,
					image: null,
					language: this.language,
					loaded: 0,
					mime: file.type,
					name: this.options['tempPrefix'] + file.id,
					original: file.name,
					percent: 0,
					remoteId: null,
					resource: !this.captionMode ? this.options['resource'] : 'Caption',
					resourceId: !this.captionMode ? this.options['resourceId'] : null,
					size: file.size,
					source: null,
					status: Status.QUEUED,
					statusMessage: null,
					tabId: this.options['tabId'],
					token: null,
					transcodingErrors: [],
					transcodingStatus: null,
					url: null,
				};
				this.uploadedFiles.push(newFile);
				this.$emit('uploadingFile', newFile);
			});

			this.filesAdded = files.length;
			this.filesProcessed = 0;

			uploader.start();
		},

		browse(file) {
			if (file) {
				this.deletedFile(file);
			}

			this.uploader.getOption('browse_button')[0].click();
		},

		/**
		 * Step 2: Before upload
		 *
		 * Before an upload begins the uploader multipart params have to be adjusted to use a random name,
		 * rather than the original file name.
		 *
		 * The file's status is set to UPLOADING.
		 */
		onBeforeUpload(uploader, file) {
			const fileName = this.getFileProperty(file.id, 'name');

			this.uploader.settings.multipart_params.key = fileName;
			this.uploader.settings.multipart_params.Filename = fileName;
			this.uploader.settings.multipart_params['Content-Type'] = file.type;

			this.setStatus(file.id, Status.UPLOADING);
		},

		/**
		 * Step 3: Before chunk upload
		 *
		 * Override settings on the uploader instance before the chunk is uploaded.
		 * The chunk size is defined in Plupload options (options.s3.chunk_size).
		 */
		onBeforeChunkUpload(uploader, file, args) {
			const chunk = args.chunk;

			if (chunk !== 0) {
				const name = this.getFileProperty(file.id, 'name');

				this.uploader.settings.multipart_params.key = name + '-' + chunk;
			}
		},

		/**
		 * Step 4: Upload progress
		 *
		 * Update the loaded/percent file properties continously.
		 */
		onUploadProgress(uploader, file) {
			this.setFileProperty(file.id, 'loaded', file.loaded);
			this.setFileProperty(file.id, 'percent', file.percent);
		},

		/**
		 * Step 5: File uploaded
		 *
		 * The file's status is set to PROCESSING and a POST request is sent to the server.
		 * A successful response contains file's token and id (remoteId).
		 */
		onFileUploaded(uploader, f) {
			const file = this.getFile(f.id);

			this.setStatus(file.id, Status.PROCESSING);

			this.$http.post(this.options['routes']['upload'], file).then(
				(response) => {
					this.setFileProperty(file.id, 'token', response.data.token);
					this.setFileProperty(file.id, 'remoteId', response.data.file);

					this.interval[file.id] = window.setInterval(
						() => (file.remoteId ? this.checkProcessingStatus(file) : this.setStatus(file.id, Status.FAILED)),
						30000
					);
				},
				() => {
					this.setStatus(file.id, Status.FAILED);
				}
			);
		},

		/**
		 * Step 6: File processing status check
		 *
		 * Do a round trip to the server to check the current file processing status.
		 */
		checkProcessingStatus(file) {
			this.$http.get(this.options['routes']['status'] + file.remoteId).then(
				(response) => {
					const processingStatus = response.data.status;

					// Skip if the processing status is still 'pending'
					if ([ProcessingStatus.OK, ProcessingStatus.REJECTED].includes(processingStatus)) {
						this.processFile(
							response.data.id,
							processingStatus,
							response.data.attachmentId,
							response.data.statusMessage,
							response.data.fileUrl,
							response.data.imageUrl,
							response.data.source
						);
					}
				},
				() => {
					this.processFile(file, ProcessingStatus.REJECTED);
				}
			);
		},

		// Handle the 'file.processed' event triggered by Pusher
		onFileProcessed(event, data) {
			this.processFile(
				data.id,
				data.status,
				data.attachmentId,
				data.statusMessage,
				data.fileUrl,
				data.imageUrl,
				data.source
			);
		},

		/**
		 * Step 7: File processing completed
		 *
		 * Handle the processed file, set its status to COMPLETED or FAILED depending on
		 * the processing result.
		 */
		processFile(
			remoteId,
			processingStatus,
			attachmentId = null,
			statusMessage = null,
			url = null,
			imageUrl = null,
			source = null
		) {
			const file = this.uploadedFiles.find((file) => file.remoteId === remoteId);

			// The file may have been processed by now
			if (!file || file.status !== Status.PROCESSING) {
				return;
			}

			const status = processingStatus === ProcessingStatus.OK ? Status.COMPLETED : Status.FAILED;

			this.setStatus(file.id, status, statusMessage);
			this.setFileProperty(file.id, 'url', url);
			this.setFileProperty(file.id, 'image', imageUrl);
			this.setFileProperty(file.id, 'source', source);

			window.clearInterval(this.interval[file.id]);

			if (status === Status.COMPLETED) {
				if (this.attachmentMode) {
					this.setFileProperty(file.id, 'oldId', file.id);
					this.setFileProperty(file.id, 'id', file.remoteId);
					this.setFileProperty(file.id, 'attachmentId', attachmentId);
				}

				if (!this.captionMode) {
					this.$emit('uploaded', this.completeFileTokens, file, attachmentId);
				}
			}

			this.completeUpload(remoteId);
		},

		completeUpload(remoteId) {
			this.filesProcessed = this.filesProcessed + 1;

			if (this.filesProcessed === this.filesAdded) {
				this.$emit('completed', this.completeFileTokens);

				if (this.captionMode) {
					// eslint-disable-next-line vue/no-mutating-props
					this.parentFile.caption = { ...this.uploadedFiles.find((file) => file.remoteId === remoteId), id: remoteId };
				}

				this.filesAdded = 0;
				this.filesProcessed = 0;
			}
		},

		// Handle the 'file.transcoding' event triggered by Pusher
		onFileTranscoding(event, data) {
			const transcodedFiles = data.data.transcodedFiles;

			transcodedFiles.forEach((transcodedFile) => {
				const file = this.uploadedFiles.find((file) => file.remoteId === transcodedFile.id);

				if (file) {
					const index = this.uploadedFiles.findIndex((file) => file.remoteId === transcodedFile.id);

					this.$set(this.uploadedFiles, index, {
						...file,
						transcodingStatus: transcodedFile.transcodingStatus,
						transcodingErrors: transcodedFile.transcodingErrors,
						image: transcodedFile.image,
						source: transcodedFile.source,
						videoHeight: transcodedFile.videoHeight,
					});
				}
			});
		},

		/**
		 * Error handler
		 */
		onError(uploader, error) {
			const errorCode = parseInt(error.code);

			if (error.file && this.getFile(error.file.id)) {
				this.setStatus(error.file.id, Status.FAILED);
			}

			this.setErrors(errorCode);

			if (this.options.errors[errorCode]) {
				this.$emit('error', this.options.errors[errorCode]);
			} else {
				tectoastr.error(
					window.navigator.onLine
						? $('#lang-strings #alerts-generic').text()
						: $('#lang-strings #alerts-no-connection').text()
				);
			}

			this.logError(uploader, error);
		},

		onPostInit() {
			var input = $('.moxie-shim.moxie-shim-html5').find('input');

			// Add aria label on input
			input.attr('aria-label', this.lang.get('files.buttons.single'));

			// Ugly hack for Facebook in-app browser
			if (isFacebookBrowser()) {
				input.removeAttr('accept');
			}
		},

		/**
		 * Cancel upload
		 */
		cancelUpload(file) {
			this.uploader.removeFile(file.id);
			this.forgetFile(file.id);

			this.emitUploadingEvent();
			if (!this.captionMode) {
				this.$emit('uploaded', this.completeFileTokens);
			}
		},

		/**
		 * File has been deleted.
		 */
		deletedFile(file) {
			if (this.attachmentMode || this.captionMode) {
				this.cancelUpload(file);
			}

			this.forgetFile(file.id);
			this.$emit('deleted', file.id, file.remoteId, !this.captionMode);

			if (this.captionMode) {
				// eslint-disable-next-line vue/no-mutating-props
				this.parentFile.caption = null;
			}
		},

		replacedFile(file) {
			if (this.attachmentMode || this.captionMode) {
				this.cancelUpload(file);
			}

			this.forgetFile(file.id);
			this.$emit('replaced', file.id, file.remoteId);
		},

		/**
		 * Retry file transcoding
		 */
		retryTranscoding(file) {
			const url = this.options.routes.retry.replace(':slug', file.id);

			this.$http.post(url).then(
				() => {},
				(error) => {
					tectoastr.error(error.response.data.message || $('#lang-strings #alerts-generic').text());
				}
			);
		},

		/**
		 * Retry file upload
		 */
		retryUpload(file) {
			// Error in backend
			if (file.statusMessage) {
				this.setFileProperty(file.id, 'statusMessage', null);
				this.onFileUploaded(this.uploader, file);
			} else {
				// Error in frontend
				const nativeFile = this.uploader.files.find((f) => f.id === file.id).getNative();
				this.uploadedFiles = this.uploadedFiles.filter((f) => f.id !== file.id);
				this.uploader.removeFile(file.id);
				this.uploader.refresh();
				this.uploader.addFile(nativeFile);
			}
		},

		/**
		 * Utility methods
		 */
		setStatus(fileId, status, statusMessage = null) {
			this.setFileProperty(fileId, 'status', status);

			if (statusMessage) {
				this.setFileProperty(fileId, 'statusMessage', statusMessage);
			}

			this.emitUploadingEvent();
			this.$emit('status', status);
		},
		getFile(fileId) {
			return this.uploadedFiles.find((file) => file.id === fileId);
		},
		getFileProperty(fileId, property) {
			return (this.uploadedFiles.find((file) => file.id === fileId) || {})[property];
		},
		setFileProperty(fileId, property, value) {
			const index = this.uploadedFiles.findIndex((file) => file.id === fileId);
			this.$set(this.uploadedFiles[index], property, value);
		},
		forgetFile(fileId) {
			const index = this.uploadedFiles.findIndex((file) => file.id === fileId);

			if (index === -1) {
				return;
			}

			this.$delete(this.uploadedFiles, index);
		},
		emitUploadingEvent() {
			const isUploading =
				this.uploadedFiles.filter((file) => [Status.QUEUED, Status.UPLOADING, Status.PROCESSING].includes(file.status))
					.length > 0;

			if (this.isUploading !== isUploading) {
				this.$emit('uploading', this.uploader.id, isUploading);
			}

			this.isUploading = isUploading;
		},
		logError(uploader, error) {
			error.urlPath = window.location.href;
			this.$http.post('/file/error', error);
		},
		dragEnter(event) {
			if (event.target.classList !== undefined && event.target.classList.contains('dnd-overlay')) {
				this.$refs['dnd-element'].classList.add('drag-enter');
				this.resetErrors();
			}
		},
		dragLeave(event) {
			if (event.target.classList !== undefined && event.target.classList.contains('dnd-overlay')) {
				this.$refs['dnd-element'].classList.remove('drag-enter');
			}
		},
		dragDrop() {
			this.$refs['dnd-element'].classList.remove('drag-enter');
		},
		setErrors(errorCode) {
			if (this.options.errors[errorCode]) {
				this.fileErrors.push(this.options.errors[errorCode]);
			}
		},
		resetErrors() {
			this.fileErrors = [];
			this.$emit('error', null);
		},
		onBrowse() {
			this.resetErrors();
		},
	},
};
</script>

<style lang="scss" scoped>
.file-uploads-transition .card {
	transition: transform 0.15s;
}

.file-limit-reached {
	padding: 0 5px;
	color: #ccc;
}

.upload-button {
	margin-top: 10px;
}

.full-width {
	width: 100%;
}

.cancel-button {
	position: relative;
	margin-top: 10px;
	z-index: 9;
}

.multilingual {
	.action-card {
		border-left-width: 0;
	}

	.cards .card {
		margin-bottom: 0;
	}
}
</style>
