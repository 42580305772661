import $ from 'jquery';
import Dialog from '@/lib/dialog';
import { mountVueComponent } from '@/lib/mount-vue-component';
import Uploader from 'uploader';
export default function () {
	// Payments - on/off switch
	var onoffswitchContainer = $('.onoffswitch-container');
	var checkbox = onoffswitchContainer.find('.onoffswitch-checkbox');
	var form = onoffswitchContainer.find('form');

	var submitForm = function () {
		setTimeout(function () {
			form.submit();
		}, 500);
	};

	var dialog = new Dialog();

	checkbox.change(function () {
		// Turn on payments instantly.
		if (this.checked) {
			submitForm();
			return;
		}

		// Turn off payments by asking for confirmation first.
		dialog.show({ content: onoffswitchContainer.data('warning') }, submitForm);
	});

	// General settings
	function toggleSection(ele, section) {
		if ($(ele).is(':checked')) {
			$(section).show();
		} else {
			$(section).hide();
		}
	}

	$('#levy-processing-fee').on('change', function () {
		toggleSection('#levy-processing-fee', '.processing-fees');
	});

	$('#accept-paypal').on('change', function () {
		toggleSection('#accept-paypal', '.paypal-settings');
	});

	$('#accept-invoice').on('change', function () {
		toggleSection('#accept-invoice', '.invoice-instructions');
	});

	// Setup the Uploader, if included on the page.
	if ($('#uploader').length > 0) {
		// eslint-disable-next-line no-undef
		new Uploader(uploaderOptions.clarify());
	}

	toggleSection('#levy-processing-fee', '.processing-fees');
	toggleSection('#accept-paypal', '.paypal-settings');
	toggleSection('#accept-invoice', '.invoice-instructions');

	// helpIcon
	import('@/lib/components/Shared/HelpIcon.vue').then((helpIcon) => {
		mountVueComponent('help-icon', helpIcon);
	});
}
