<script>
import validatorMixin from './validator/mixin.js';
import errorMixin from '@/lib/components/Fields/validator/error';
import { validationRules } from '@/lib/components/Fields/validator';
import { mapMutations } from 'vuex';

/**
 * The base Field component, all field types should extend it.
 */
export default {
	mixins: [validatorMixin, errorMixin],
	props: {
		field: {
			type: Object,
			required: true,
		},
		value: {
			type: [String, Number, Boolean, Array, Object],
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		isManager: {
			type: Boolean,
			default: false,
		},
		foreignId: {
			type: Number,
			default: null,
		},
		inActiveTab: {
			type: Boolean,
			default: false,
		},
		elementId: {
			type: String,
			default: '',
		},
		id: {
			type: String,
			default: null,
		},
	},
	data() {
		return validationRules(this.field.type, this.rules, this.required);
	},
	methods: {
		...mapMutations('global', ['setLastModifiedField']),
		// Every time the value changes emit an 'input' event - add support for v-model
		onInput(value) {
			this.$emit('input', value);
			this.$emit('update:value', value);

			this.setLastModifiedField(this.field.slug);
		},
		getId() {
			return this.id || this.field.slug + '-' + this.elementId;
		},
	},
};
</script>
