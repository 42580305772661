export const hashObject = obj => JSON.stringify(obj);

export const makeId = () => {
  const randomNumber = Math.random()
    .toString()
    .slice(2, 12);
  const timestamp = new Date().getTime();

  return `new-${randomNumber}-${timestamp}`;
};

/**
 * Transform file types into an object ready to be submitted
 * as part of form data
 */
export const transformFileTypes = fileTypes => {
  const extensions = {};

  Object.keys(fileTypes).forEach(group => {
    fileTypes[group].forEach(extension => {
      extensions[group + '.' + extension] = extension;
    });
  });

  return extensions;
};
