import { useVideoPlayerApi } from '@/domain/services/VideoPlayer/VideoPlayerApi';
import { TMediaError, TPlayer } from '@/domain/services/VideoPlayer/VideoPlayerWrapper';

const videoPlayerErrorHandler = (player: TPlayer, error: TMediaError) => {
	const playerId = player.id();
	const fileId = playerId.split('-').pop();

	if (fileId === undefined) {
		return;
	}

	console.error('Video player error', error);

	const errorData = {
		code: error.code,
		message: error.message,
		status: error.status,
		fileId: fileId,
		location: window.location.pathname,
		userAgent: window.navigator.userAgent,
	};

	useVideoPlayerApi.postLogError(fileId, errorData);
};

export default videoPlayerErrorHandler;
