<template>
	<div v-resize="onResize" class="marker-action">
		<list-action-form
			v-if="!isHidden"
			ref="form"
			:ids="ids"
			:labels="labels"
			:route="grantStatusRoute"
			:method="method"
			:show-submit="mode === 'bulk'"
			@submitted="toggle"
		>
			<input type="hidden" name="grantStatus" :value="grantStatus" />
			<portal v-if="mode === 'bulk'" to="grant-status-selector">
				<div key="grant-status-selector" class="marker-action-bulk-grant-status">
					<div class="form-group row">
						<div class="col-sm-4">
							<select-field
								name="grantStatuses"
								:items="grantStatuses"
								:empty-option="mode !== ''"
								id-property="slug"
								value-property="name"
								:aria-label="labels.label"
								:disabled="disabled"
								@selected="(name, slug) => (grantStatus = slug)"
							/>
						</div>
						<div class="col-sm-3">
							<a :class="['btn', 'btn-lg', 'btn-secondary']" @click.prevent="submit">
								{{ labels.button }}
							</a>
						</div>
					</div>
				</div>
			</portal>
			<div v-else class="inline-action-single-grant-status">
				<select-field
					name="grantStatuses"
					:items="grantStatuses"
					:empty-option="true"
					id-property="slug"
					:aria-label="labels.label"
					value-property="name"
					:value="grantStatus"
					:disabled="disabled"
					@selected="onSelect"
				/>
			</div>
		</list-action-form>
		<span v-if="mode !== 'bulk' && isHidden" class="grant-status-selector">{{ selectedStatusLabel }}</span>
	</div>
</template>

<script>
import ListAction from './ListAction.vue';
import { SelectField } from 'vue-bootstrap';
import resize from '@/lib/directives/resize.js';

export default {
	components: {
		SelectField,
	},
	extends: ListAction,
	directives: {
		resize,
	},
	props: {
		grantStatuses: {
			type: Array,
			required: true,
		},
		mode: {
			type: String,
			default: 'bulk',
		},
		selectedStatus: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			grantStatus: this.selectedStatus,
			isHidden: true,
		};
	},
	computed: {
		grantStatusRoute() {
			return this.route + (this.grantStatus !== null ? '/' + this.grantStatus : '');
		},
		selectedStatusLabel() {
			const status = this.grantStatuses.find((grantStatus) => grantStatus.slug === this.selectedStatus);
			return status ? status.name : '—';
		},
	},
	methods: {
		toggle() {
			this.$emit('reveal', 'grant-status-selector');
		},
		onSelect(el, slug) {
			if (this.grantStatus !== slug) {
				this.grantStatus = slug;
				this.$nextTick(() => this.submit());
			}
		},
		onResize({ width }) {
			// This condition checks if the GrantStatusSelector is positioned beneath a hidden datatable panel,
			// typically concealed under the "+" icon. In such cases, only the status text is visible as no component functions are accessible.
			this.isHidden = !width;
		},
	},
};
</script>

<style scoped>
.inline-action-single-grant-status {
	max-width: 250px;
}
</style>
