<script>
import DraggableColumns from './DraggableColumns.vue';

export default {
  components: {
    DraggableColumns
  },
  props: {
    area: {
      type: String,
      required: true
    },
    columns: {
      type: Array,
      default: () => []
    },
    selected: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedArea: '',
      availableColumns: [],
      selectedColumns: []
    };
  },
  created() {
    this.selectedArea = this.area;
    this.availableColumns = this.columns;
    this.selectedColumns = this.selected;
  }
};
</script>
