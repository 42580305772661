/**
 * Bootstrap breaking application dependencies.
 * Order of execution and contents of this file are important.
 * Do not modify without a good reason and understanding.
 *
 * This file is always-first import at app entry point.
 * ( i.e. main.js )
 */

import { Composer } from '@/domain/services/Composer';
import { VueDataProvider } from '@/domain/services/VueDataProvider';

// eslint-disable-next-line @typescript-eslint/naming-convention
declare const window: { App: { [key: string]: unknown } };
VueDataProvider.bootGlobalData(window.App);

Composer.boot();
