<template>
  <div class="input-group">
    <span class="input-group-addon">
      {{ currency.symbol }}
    </span>
    <input
      type="text"
      :name="name"
      :value="amount"
      class="form-control input-sm"
    />
  </div>
</template>

<script>
export default {
  props: {
    currency: {
      type: Object,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    amount: {
      type: String,
      default: ''
    }
  }
};
</script>
