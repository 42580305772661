/* This file was generated by running the 'lang:refresh-js' command. */

require('../vendor/select2/select2.js');
require('../vendor/select2/select2_locale_ar.js');
require('../vendor/select2/select2_locale_cs.js');
require('../vendor/select2/select2_locale_da.js');
require('../vendor/select2/select2_locale_de.js');
require('../vendor/select2/select2_locale_el.js');
require('../vendor/select2/select2_locale_es.js');
require('../vendor/select2/select2_locale_es.js');
require('../vendor/select2/select2_locale_fa.js');
require('../vendor/select2/select2_locale_fi.js');
require('../vendor/select2/select2_locale_fr.js');
require('../vendor/select2/select2_locale_fr.js');
require('../vendor/select2/select2_locale_he.js');
require('../vendor/select2/select2_locale_hi.js');
require('../vendor/select2/select2_locale_it.js');
require('../vendor/select2/select2_locale_ja.js');
require('../vendor/select2/select2_locale_ko.js');
require('../vendor/select2/select2_locale_lt.js');
require('../vendor/select2/select2_locale_ms.js');
require('../vendor/select2/select2_locale_nl.js');
require('../vendor/select2/select2_locale_nb.js');
require('../vendor/select2/select2_locale_pl.js');
require('../vendor/select2/select2_locale_pt-BR.js');
require('../vendor/select2/select2_locale_ru.js');
require('../vendor/select2/select2_locale_sv.js');
require('../vendor/select2/select2_locale_sw.js');
require('../vendor/select2/select2_locale_th.js');
require('../vendor/select2/select2_locale_uk.js');
require('../vendor/select2/select2_locale_vi.js');
require('../vendor/select2/select2_locale_zh-CN.js');

$.extend($.fn.select2.defaults, $.fn.select2.locales['en']);
