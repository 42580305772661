var $ = require('jquery');
var underscore = require('underscore');
var moment = require('moment');
var tectoastr = require('tectoastr');

/**
 * AutoSaver module.
 *
 * Handles the magical AutoSave functionality required for Entries, and potentially other forms too.
 *
 * Add the 'autosaver' class to any <form> element and it will automatically submit the form in the background
 *  after a small delay when the user stops typing.
 *
 * Add a field with the class 'autosaver-status' for a simple status message.
 *
 * You can manually trigger it with:
 *   AutoSaver.save(function () { ... });
 */

module.exports = function (form, successCallback, errorCallback, waitForAsyncInit = false) {
	// Config
	var debug = false;
	var isDisabled = false;
	var saving = false;
	var timeout = null;
	var wait = 15000;
	var supressWarnings = false;

	var savedFormData = [];
	var recentFormData = [];

	if (debug) wait = Math.floor(wait / 15);

	var cacheForm = function () {
		let currentFormData = form
			.serializeArray()
			.filter((e) => !['updatedAt', '_method', '_token', 'entrytab'].includes(e.name));

		savedFormData = [];
		currentFormData.forEach((e) => (savedFormData[e.name] = e.value));

		recentFormData = savedFormData;

		if (debug) {
			// eslint-disable-next-line no-console
			console.log(savedFormData);
		}
	};

	/**
	 * Set the wait time
	 */
	this.setWaitTime = function (time) {
		wait = time;
	};

	/**
	 * Toggles the option to supress warnings
	 */
	this.setSupressWarnings = function (toggle) {
		supressWarnings = toggle;
	};

	/**
	 * Define success callback
	 */
	this.setSuccessCallback = function (callback) {
		successCallback = callback;
	};

	/**
	 * Define error callback
	 */
	this.setErrorCallback = function (callback) {
		errorCallback = callback;
	};

	/**
	 * Manually trigger autosave.
	 */
	this.save = function (customSuccessCallback, customErrorCallback) {
		if (debug) {
			// eslint-disable-next-line no-console
			console.log('save()');
		}

		processSave('save', customSuccessCallback, customErrorCallback);
	};

	/**
	 * Manually trigger autosave.
	 */
	this.saveOnce = function (customSuccessCallback, customErrorCallback) {
		if (debug) {
			// eslint-disable-next-line no-console
			console.log('saveOnce()');
		}

		var disabledState = isDisabled;
		isDisabled = false;
		processSave('saveOnce', customSuccessCallback, customErrorCallback);
		isDisabled = disabledState;
	};

	/**
	 * Manually trigger a saveClose action.
	 */
	this.saveClose = function (url) {
		$('#loader').show().fadeTo(250, 0.8);

		if (saving) {
			setTimeout(() => {
				this.saveClose(url);
			}, 1000);
			return;
		}

		isDisabled = true;
		processSave(
			// action
			'saveClose',
			// success
			function () {
				$.pjax({ url: url });
			},
			// error
			function () {
				isDisabled = false;
				$('#loader').fadeTo(250, 0, function () {
					$(this).hide();
				});
			}
		);
	};

	/**
	 * Manually trigger a saveSubmit action.
	 */
	// eslint-disable-next-line no-unused-vars
	this.saveSubmit = function () {
		$('#loader').show().fadeTo(250, 0.8);

		if (saving) {
			setTimeout(() => {
				this.saveSubmit();
			}, 1000);
			return;
		}

		isDisabled = true;
		processSave(
			// action
			'saveSubmit',
			// success
			function () {
				$(form).submit();
			},
			// error
			function () {
				isDisabled = false;
				$('#loader').fadeTo(250, 0, function () {
					$(this).hide();
				});
			}
		);
	};

	/**
	 * Disable AutoSaver
	 */
	this.disable = function () {
		if (debug) {
			// eslint-disable-next-line no-console
			console.log('disable()');
		}

		isDisabled = true;
		saving = false;
	};

	/**
	 * Enable AutoSaver
	 */
	this.enable = function () {
		if (debug) {
			// eslint-disable-next-line no-console
			console.log('enable()');
		}

		isDisabled = false;
	};

	/**
	 * Trigger the autosave
	 * (piping through here to avoid event passed in as callback)
	 */
	var triggerAutoSave = function () {
		if (isDisabled) {
			return;
		}

		processSave('autosave');
	};

	/**
	 * Process the autosave and call the callback on success
	 */
	var processSave = function (action, customSuccessCallback, customErrorCallback) {
		if (debug) {
			// eslint-disable-next-line no-console
			console.log('processSave()');
		}

		if (saving || (isDisabled && customSuccessCallback === undefined)) {
			if (debug) {
				// eslint-disable-next-line no-console
				console.log('Skipping save, existing save running or disabled.');
			}

			return;
		}

		const currentFormData = getCurrentFormData();
		/* eslint-disable-next-line eqeqeq */
		const diff = currentFormData.filter((f) => f.value != savedFormData[f.name]);

		if (['save', 'saveOnce', 'autosave'].includes(action) && !diff.length) {
			if (debug) {
				// eslint-disable-next-line no-console
				console.log("Skipping save, form data didn't change.");
			}

			return;
		}

		saving = true;
		updateStatus('saving');

		$.ajax({
			type: 'PUT',
			url: $(form).data('autosave'),
			data: $(form).serialize(),
			dataType: 'json',
			timeout: 20000,
		})
			.success(function (response) {
				if (debug) {
					// eslint-disable-next-line no-console
					console.log(response);
				}

				$(form).find('input[name=updatedAt]').val(response.updatedAt);

				if (successCallback !== undefined) {
					successCallback(response);
				}

				if (customSuccessCallback !== undefined) {
					customSuccessCallback(response);
				}

				if (response.message !== undefined) {
					makeToast(response);
				}

				updateStatus('success');
				cacheForm();
			})
			.error(function (response, textStatus) {
				// Recover from a timeout error and attempt to save the form again
				if (textStatus === 'timeout') {
					var timestamp = moment.utc().format('YYYY-MM-DD HH:mm:ss');
					$(form).find('input[name=updatedAt]').val(timestamp);
				}

				let currentFormData = form
					.serializeArray()
					.filter((e) => !['updatedAt', '_method', '_token', 'entrytab'].includes(e.name) && !e.name.match(/^.*\[\]$/gm));
				/* eslint-disable-next-line eqeqeq */
				let diff = currentFormData.filter((f) => savedFormData[f.name] != f.value).map((f) => f.name);

				if (debug) {
					// eslint-disable-next-line no-console
					console.log(form.serializeArray(), savedFormData, currentFormData, diff);
				}

				makeToast(response.responseJSON, diff);
				updateStatus('error');

				if (errorCallback !== undefined) {
					errorCallback(response);
				}

				if (customErrorCallback !== undefined) {
					customErrorCallback(response);
				}
			})
			.complete(function () {
				saving = false;
			});
	};

	/**
	 * Makes a toast with the json data.
	 */
	var makeToast = function (data, diff = false) {
		if (data === undefined) {
			tectoastr.error($(form).find('.autosaver-status').data('error'));
			return;
		}

		var messages = [];

		if (data.message) {
			messages.push(data.message);
		}

		if (data.title) {
			messages.push(data.title);
		}

		if (data.chapterId) {
			messages.push(data.chapterId);
		}

		if (data.categoryId) {
			messages.push(data.categoryId);
		}

		if (Array.isArray(diff) && diff.length) {
			let messagesList = [];

			const getFieldsForName = (n) =>
				$(form)
					.find("[name='" + n + "']")
					.toArray();
			const getfieldId = (f) => f.name.replace(/^values\[([^\]]+)\].*/gm, '$1');

			const processLabel = (label) => {
				label.parent().addClass('error');
				messagesList.push('<li>' + label.html() + '</li>');
			};

			Array.from(
				new Set(
					diff
						.map((d) => getFieldsForName(d))
						.reduce((a, b) => a.concat(b))
						.map((f) => getfieldId(f))
				)
			)
				.map((n) => $(form).find(`#${n}`))
				.forEach((label) => label.html() && processLabel(label));

			if (messagesList.length) messages = messages.concat(['', '<ul>' + messagesList.join('') + '</ul>']);
		}

		if (messages.length) {
			switch (data.type) {
				case 'info':
					tectoastr.info(messages.join('<br>'));
					break;
				case 'warning':
					if (!supressWarnings) {
						tectoastr.warning(messages.join('<br>'), Array.isArray(diff) && diff.length);
					}

					break;
				default:
					tectoastr.error(messages.join('<br>'));
					break;
			}
		}
	};

	/**
	 * Updates the status message.
	 */
	var updateStatus = function (message) {
		var element = $(form).find('.autosaver-status');
		element.text(element.data(message));

		if (debug) {
			// eslint-disable-next-line no-console
			console.log(element.data(message));
		}

		if (timeout) {
			clearTimeout(timeout);
		}

		if (message === 'success') {
			timeout = setTimeout(function () {
				element.fadeOut(function () {
					$(this).html('&nbsp;').show();
				});
			}, 3000);
		}
	};

	var getCurrentFormData = function () {
		let currentFormData = form
			.serializeArray()
			.filter((e) => !['updatedAt', '_method', '_token', 'entrytab'].includes(e.name) && !e.name.match(/^.*\[\]$/gm));

		let assoc = [];
		currentFormData.forEach((v, k) => (assoc[v.name] = k));

		let ret = [];
		for (let i in assoc) ret.push(currentFormData[assoc[i]]);

		return ret;
	};

	var debouncedTriggerAutoSave = underscore.debounce(triggerAutoSave, wait);

	this.init = function () {
		cacheForm();
		setTimeout(function () {
			let currentFormData = getCurrentFormData();
			recentFormData = [];
			currentFormData.forEach((e) => (recentFormData[e.name] = e.value));
			setInterval(function () {
				let currentFormData = getCurrentFormData();
				/* eslint-disable-next-line eqeqeq */
				let diff = currentFormData.filter((f) => recentFormData[f.name] != f.value).map((f) => f.name);
				if (diff.length) {
					recentFormData = [];
					currentFormData.forEach((e) => (recentFormData[e.name] = e.value));
					debouncedTriggerAutoSave();
				}
			}, 100);
		}, 500);
	};

	if (waitForAsyncInit === false) this.init();
};
