var mountVueComponent = require('@/lib/mount-vue-component').mountVueComponent;

var $ = require('jquery');
/* eslint-disable @typescript-eslint/naming-convention */
var Audio = require('../../lib/audio.js');
var Cards = require('../../lib/cards.js');
var tectoastr = require('tectoastr');
var VideoPlayer = require('@/domain/services/VideoPlayer/VideoPlayer').default;
var VideoModal = require('@/domain/services/VideoPlayer/VideoPlayerModal').default;
var VideoThumbnails = require('../../lib/video-thumbnails.js');
var isMobile = require('@/lib/navigation-is-mobile');
/* eslint-enable @typescript-eslint/naming-convention */

module.exports = function () {
	if (!isMobile()) {
		$('.header-controls').stickybits({
			useStickyClasses: true,
		});
	}

	/**
	 * Cards
	 */
	var cards = new Cards();
	cards.setup('ul.cards', 'li', 'fitRows');

	/**
	 * Audio
	 */
	var audio = new Audio();
	audio.setup('jp-jplayer');

	/**
	 * Video
	 */
	var videoThumbnails = new VideoThumbnails();

	VideoPlayer.setup('video-js-standalone');
	VideoModal.setup('play-video');
	videoThumbnails.setup('preview-container.video');

	/**
	 * Decide (pass/unsure/fail) buttons
	 */
	var buttons = $('.decide-button, .undecide-button');
	var undecideButton = $('.undecide-button');

	$(buttons).on('click', function () {
		var button = $(this);
		if (!button.data('url')) {
			return;
		}

		var decision = button.data('decision');

		// Check if already deciding
		if (button.hasClass('deciding')) {
			return;
		}

		button.addClass('deciding');

		// Send new decision to server
		$.ajax({
			type: 'POST',
			url: button.data('url'),
			data: { decision: decision },
			dataType: 'json',
		})
			.done(function () {
				// Update button
				buttons.removeClass('deciding pass fail unsure');
				button.addClass(decision);

				// Show/hide Undecide Button
				undecideButton.toggleClass('hidden', !decision);
			})
			.fail(function (response) {
				// Throw error
				tectoastr.response(response.responseJSON, response.responseJSON.message || button.data('failed'));

				// Toggle button back
				button.removeClass('deciding');
			});
	});

	/** Comments */
	import('@/lib/components/Comments/Comments.vue').then((comments) => {
		mountVueComponent('comments', comments);
	});
};
