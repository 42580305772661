<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th colspan="3">
            {{ lang.get('payments.prices.form.amounts.columns.description') }}
          </th>
          <th v-for="currency in currencies" :key="`header-${currency.code}`">
            {{ currency.code }}
          </th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <draggable
        tag="tbody"
        handle=".dragger"
        animation="150"
        :force-fallback="true"
      >
        <price-variant
          v-for="(variant, index) in variants"
          :key="`price-variant-${variant.id}`"
          :variant="variant"
          :position="index"
          :currencies="currencies"
          :chapters="chapters"
          :chapter-visible="chapterVisible"
          :categories="categories"
          :fields="fields"
          :field-values="fieldValues"
          @deleted="deleteVariant"
          @chapterSelected="updateVariant"
          @fieldSelected="updateVariant"
          @fieldValueSelected="updateVariant"
        />
      </draggable>
      <tbody>
        <tr>
          <td colspan="3" valign="center">
            {{ lang.get('payments.prices.form.amounts.default') }}
          </td>
          <td
            v-for="currency in currencies"
            :key="`default-amount-${currency.code}`"
          >
            <price-amount
              :name="`defaultAmount[amounts][${currency.code}]`"
              :amount="
                ((defaultAmount || {})['amounts'] || {})[currency.code] || ''
              "
              :currency="currency"
            />
          </td>
          <td></td>
          <td></td>
        </tr>
      </tbody>
    </table>
    <button
      v-if="enableVariants"
      class="btn btn-secondary"
      @click.prevent="addVariant"
    >
      {{ lang.get('payments.prices.form.amounts.add') }}
    </button>
  </div>
</template>

<script>
import Draggable from 'vuedraggable';
import PriceVariant from './PriceVariant';
import PriceAmount from './PriceAmount';

export default {
  inject: ['lang'],
  components: {
    Draggable,
    PriceVariant,
    PriceAmount
  },
  props: {
    currencies: {
      type: Array,
      default: () => []
    },
    amounts: {
      type: Array,
      default: () => []
    },
    defaultAmount: {
      type: Object,
      default: () => ({})
    },
    chapters: {
      type: Array,
      default: () => []
    },
    chapterVisible: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array,
      default: () => []
    },
    enableVariants: {
      type: Boolean,
      default: false
    },
    fields: {
      type: Array,
      default: () => []
    },
    fieldValues: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      variants: null
    };
  },
  created() {
    this.variants = JSON.parse(JSON.stringify(this.amounts));
  },
  methods: {
    deleteVariant(variant) {
      this.variants = this.variants.filter(v => v.id !== variant.id);
    },
    addVariant() {
      this.variants.push({
        id: 'new-' + Date.now(),
        amounts: {},
        categoryId: '',
        chapterId: ''
      });
    },
    updateVariant(id, property, value) {
      const index = this.variants.findIndex(v => v.id === id);

      if (index >= 0) {
        this.$set(this.variants[index], property, value);
      }
    }
  }
};
</script>
