import { commentsServiceFactory } from '@/lib/components/Comments/commentsService';
import store from '@/lib/store';
import { trans } from '@/services/global/translations.interface';
import Uploader from '@/lib/components/Uploader/Uploader.vue';
import { useGlobal } from '@/services/global/global.interface';
import {
	canUploadFiles,
	deletedFile,
	imageDataUrl,
	uploadCanceled,
	uploadCompleted,
	uploadedFile,
	uploadingFile,
	useFiles,
} from '@/lib/components/Shared/editor/uploads/uploadsLogic';
import { Error, errorHandler } from '@/lib/components/Comments/errorHandler';
import {
	NewCommentCreatedResponse,
	NewCommentProps,
	NewCommentRequestPayload,
	NewCommentView,
} from '@/lib/components/Comments/NewComment.types';
import { NewCommentEmitters, NewCommentEvents } from '@/lib/components/Comments/NewComment.events';
import Vue, { computed, ref, SetupContext, watch } from 'vue';

const newCommentControllerFactory =
	() =>
	(props: NewCommentProps, emit: NewCommentEmitters): NewCommentView => {
		const { files, canceledFiles } = useFiles();

		const uploader = ref<typeof Uploader>(null);
		const commentContent = ref('');
		const oldCommentContent = ref('');
		const disabled = ref(false);

		const canUpload = computed(() => canUploadFiles(props));
		const showSaveCommentButton = computed(() => !!((commentContent.value || '').length || files.value.length));

		const commentsService = commentsServiceFactory();
		const pendingUploads = computed(commentsService.pendingUploads as () => number);
		const userId = commentsService.userId();

		watch(
			() => showSaveCommentButton,
			(value, oldValue) => {
				if (value && value !== oldValue) {
					store.commit('comments/changeCommentsBeingEdited', +1);
				} else if (!value && value !== oldValue) {
					store.commit('comments/changeCommentsBeingEdited', -1);
				}
			}
		);
		watch(
			() => commentContent,
			(newValue) => {
				if (newValue.value.length > 0 && oldCommentContent.value.length === 0) {
					emit(NewCommentEvents.Commenting);
				}

				oldCommentContent.value = newValue.value;
			},
			{ deep: true }
		);

		const createComment = () => {
			disabled.value = true;

			Vue.prototype.$http
				.post(props.createUrl, {
					comment: commentContent.value,
					fileTokens: files.value.map((f) => f.token),
					token: props.token,
				})
				.then(
					(success: { data: NewCommentCreatedResponse }) => {
						const newComment: NewCommentRequestPayload = success.data;
						newComment.comment = newComment.content;
						newComment.files = JSON.parse(JSON.stringify(files.value));

						commentContent.value = '';
						files.value = [];

						emit(NewCommentEvents.Added, newComment);
					},
					(error: Error) => errorHandler(error)
				)
				.finally(() => {
					disabled.value = false;
				});
		};

		return {
			useGlobal,
			lang: trans,

			commentContent,
			uploader,
			disabled,
			files,
			canUpload,
			showSaveCommentButton,
			pendingUploads,

			createComment,

			onUpload: () => uploader.value.$refs.button.click(),
			onUploadingFile: (file) => uploadingFile(file, files, commentsService, userId),
			onUploadedFile: (completeFileTokens, file) =>
				uploadedFile(completeFileTokens, file, files, commentsService, canceledFiles, userId),
			onUploadCompleted: (completeFileTokens) => uploadCompleted(completeFileTokens, trans),
			onImageDataUrl: (dataUrl, file) => imageDataUrl(dataUrl, file, files),
			onUploadCanceled: (file) => uploadCanceled(file, files, commentsService, canceledFiles),
			onDeletedFile: (file) => deletedFile(file, files, null),
		};
	};

const newCommentController = (props: NewCommentProps, ctx: SetupContext) =>
	newCommentControllerFactory()(props, ctx.emit);

export { newCommentController, newCommentControllerFactory };
