<template>
  <tr :class="{ advanced: settingsVisible }">
    <td class="dragger">
      <img src="/img/draggable-icon.png" />
    </td>
    <td>
      <div class="relative">
        <select-field
          v-if="chapterVisible"
          :id="`amount-${position}-chapter-id`"
          class="input-sm"
          :name="`amount[${position}][chapterId]`"
          :value="variant.chapterId || ''"
          :items="availableChapters"
          @selected="
            (name, chapterId) =>
              $emit('chapterSelected', variant.id, 'chapterId', chapterId)
          "
        />
        <advanced-settings
          v-show="settingsVisible"
          :position="position"
          :variant="variant"
          :fields="fields"
          :field-values="fieldValues"
          @fieldSelected="
            (id, property, value) => $emit('fieldSelected', id, property, value)
          "
          @fieldValueSelected="
            (id, property, value) =>
              $emit('fieldValueSelected', id, property, value)
          "
        />
      </div>
    </td>
    <td>
      <select-field
        :id="`amount-${position}-category-id`"
        class="input-sm"
        :name="`amount[${position}][categoryId]`"
        :value="variant.categoryId || ''"
        :items="availableCategories"
      />
    </td>
    <td
      v-for="currency in currencies"
      :key="`amount-${position}-${currency.code}`"
    >
      <price-amount
        :name="`amount[${position}][amounts][${currency.code}]`"
        :amount="variant['amounts'][currency.code] || ''"
        :currency="currency"
      />
    </td>
    <td>
      <a
        href
        class="enable-advanced-settings"
        @click.prevent="settingsVisible = !settingsVisible"
      >
        {{ lang.get('miscellaneous.advanced_search.label') }}
      </a>
    </td>
    <td>
      <span class="button-pill-close standalone">
        <a
          href
          class="deleter-action"
          @click.prevent="$emit('deleted', variant)"
        >
          <icon name="close-circle" />
        </a>
      </span>
    </td>
  </tr>
</template>

<script>
import { SelectField } from 'vue-bootstrap';
import Icon from '@/lib/components/Shared/Icon';
import PriceAmount from './PriceAmount';
import AdvancedSettings from './AdvancedSettings';

export default {
  inject: ['lang'],
  components: {
    Icon,
    PriceAmount,
    SelectField,
    AdvancedSettings
  },
  props: {
    variant: {
      type: Object,
      default: () => ({})
    },
    position: {
      type: Number,
      default: 0
    },
    currencies: {
      type: Array,
      default: () => []
    },
    chapters: {
      type: Array,
      default: () => []
    },
    chapterVisible: {
      type: Boolean,
      default: false
    },
    categories: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    fieldValues: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      // Initially, it makes sense to show advanced settings if the price variant
      // already has a fieldId defined.
      settingsVisible: this.variant.fieldId != null
    };
  },
  computed: {
    availableChapters() {
      const allChapters = {
        id: '',
        name: this.lang.get('payments.prices.amount_selectors.chapters')
      };

      return [allChapters, ...this.chapters];
    },
    availableCategories() {
      const allCategories = {
        id: '',
        name: this.lang.get('payments.prices.amount_selectors.categories')
      };

      if (this.variant.chapterId) {
        return [
          allCategories,
          ...this.categories.filter(category =>
            category.chapterIds.includes(this.variant.chapterId)
          )
        ];
      }

      return [allCategories, ...this.categories];
    }
  }
};
</script>

<style scoped>
.table tr.advanced {
  height: 110px;
}

.table td {
  vertical-align: top;
}

.enable-advanced-settings {
  line-height: 1.8;
}

.relative {
  position: relative;
}
</style>
