import { Multiselect } from 'vue-bootstrap';
import SelectDropdown from '@/lib/components/Shared/SelectDropdown.vue';
var $ = require('jquery');
/* eslint-disable-next-line @typescript-eslint/naming-convention */
var Vue = require('vue');
/* eslint-disable @typescript-eslint/naming-convention */
var AttachmentTypes = require('../../lib/attachment-types.js');
var AccessMatrix = require('./access-matrix.js');
/* eslint-enable @typescript-eslint/naming-convention */

export default function () {
	const vueInstance = new Vue({
		el: '#review-flow-form',
		name: 'ReviewFlow',
		components: { Multiselect, SelectDropdown },
	});

	$(document).one('pjax:end', () => {
		vueInstance.$destroy();
	});

	/**
	 * Toggle options
	 */
	var toggleOptions = function () {
		$('.toggle-block').addClass('hidden');

		$('.toggle-options').each(function () {
			if ($(this).prop('checked')) {
				$('.toggle-only-' + $(this).attr('id')).removeClass('hidden');
			}
		});
	};

	let hideStopAction = document.getElementById('hideStopAction');
	if (hideStopAction !== null) {
		let proceedOptions = document.getElementById('all-stop-options');
		hideStopAction.addEventListener('click', () => {
			if (proceedOptions.classList.contains('hidden')) {
				proceedOptions.classList.remove('hidden');
			} else {
				proceedOptions.classList.add('hidden');
			}
		});
	}

	// Bind and fire on load
	$('.toggle-options').on('change', toggleOptions);

	toggleOptions();

	// Access matrix
	new AccessMatrix();

	// Initialise attachment types toggles
	new AttachmentTypes();
}
