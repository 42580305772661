<template>
  <div>
    <currency-field
      :field="field"
      :value="value"
      :name="name"
      :has-error="hasError"
      @input="updateValueData"
    ></currency-field>
    <input type="hidden" :name="name" :value="valueData" />
  </div>
</template>

<script>
import CurrencyField from './CurrencyField';
import errorMixin from '@/lib/components/Fields/validator/error';

export default {
  components: { CurrencyField },
  mixins: [errorMixin],
  props: {
    field: {
      type: Object,
      required: true
    },
    value: {
      type: [Number, String],
      required: true
    }
  },
  data() {
    return {
      valueData: this.value
    };
  },
  methods: {
    updateValueData(v) {
      this.valueData = v;
    }
  }
};
</script>
