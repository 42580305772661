var $ = require('jquery');
var Vue = require('vue');
var InlineCurrencyField = require('../lib/components/Fields/InlineCurrencyField').default;

module.exports = {
  /**
   * Sets up the relevant elements with a currency field input component.
   *
   * @param collection elements
   */
  setup: function(elements) {
    for (var i = 0; i < elements.length; i++) {
      new Vue({
        el: elements[i],
        name: 'CurrencyInputApp' + i,
        components: {
          'inline-currency-field' : InlineCurrencyField
        },
        provide: function() {
          return {
            lang: {
              locale: App.language
            }
          };
        }
      });
    }
  }
};
