import MediaError from 'video.js/dist/types/media-error';
import Player from 'video.js/dist/types/player';
import { VideoPlayerOptions } from '@/domain/services/VideoPlayer/VideoPlayerTypes';
import videojs, { ReadyCallback } from 'video.js';

import 'videojs-contrib-quality-menu';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface TPlayer extends Player {
	qualityMenu(): void;
	// eslint-disable-next-line @typescript-eslint/naming-convention
	tech_: {
		vhs: {
			xhr: {
				onRequest: (callback: (options: { uri: string }) => void) => void;
			};
		};
	};
}

// eslint-disable-next-line @typescript-eslint/naming-convention
interface TMediaError extends MediaError {
	fileId?: string;
}

const instantiatePlayer = (
	videoId: string,
	options: VideoPlayerOptions,
	callback: (player: TPlayer) => void
): TPlayer => videojs(videoId, options, callback as ReadyCallback) as TPlayer;

const getPlayer = (videoId: string): TPlayer => videojs.getPlayer(videoId) as TPlayer;

const addVideoQualitySelector = (player: TPlayer): TPlayer => {
	player.qualityMenu();
	return player;
};

export { instantiatePlayer, getPlayer, addVideoQualitySelector, TPlayer, TMediaError };
