<template>
	<div :id="getWrapperId" :class="{ darkmode: isDarkMode }">
		<input v-if="hasNoFieldRelation" type="hidden" :name="name" :value="storeValue" />
		<input
			:id="getId()"
			ref="input"
			:value="numberWithoutCountryCode()"
			class="form-control phone-number"
			type="text"
			dir="ltr"
			:disabled="disabled"
			:aria-invalid="hasError"
			:aria-describedby="errorAria"
			:aria-required="field.required ? 'true' : 'false'"
			@keypress="isPhoneNumber($event)"
			@keyup.stop="onInput($event)"
			@input="onInput($event)"
			@countrychange="onCountryChange($event)"
			@blur="validateAndVerify"
		/>
		<span v-if="fetching" class="af-icons af-icons-repeat af-icons-animate-rotate fetchingIcon"></span>
		<span v-if="showMobileExistsAlerts" class="af-icons af-icons-cross mobileExistsIcon"></span>
		<div v-if="showMobileExistsAlerts" class="mobileExistsText">
			{{ lang.get('home.register.user_with_mobile_exists') }}
		</div>
		<span v-if="fullNumber && mobileDoesNotExist" class="af-icons af-icons-tick mobileDoesNotExistIcon"></span>
		<div v-if="!valid" class="mobileInvalid">
			{{ lang.get('home.register.invalid_mobile') }}
		</div>
	</div>
</template>

<script>
import intlTelInput from 'intl-tel-input';
import Field from './Field.vue';
import langMixin from '@/lib/components/Translations/mixins/lang-mixin.js';
import phoneValidator from '@/lib/components/Fields/validator/phone.js';
import { isEnabled as isDarkReaderEnabled } from 'darkreader';
import { mapGetters } from 'vuex';

export default {
	extends: Field,
	mixins: [langMixin],
	props: {
		field: {
			type: Object,
			default: () => ({ id: null, value: null }),
		},
		id: {
			type: String,
			default: null,
		},
		country: {
			type: String,
			default: null,
		},
		phoneWithLeadingZeros: {
			type: Array,
			default: () => [],
		},
		verify: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			fullNumber: null,
			storeValue: null,
			iti: null,
			mobileExists: false,
			mobileDoesNotExist: false,
			fetching: false,
			valid: true,
			isDarkMode: false,
		};
	},
	computed: {
		...mapGetters('global', ['detectedCountry']),
		getWrapperId() {
			return this.getId() + '-wrapper';
		},
		hasNoFieldRelation() {
			return !this.field || !this.field.id;
		},
		isRtl() {
			return (document.dir || '') === 'rtl';
		},
		preferredCountry() {
			return [this.country ? this.country : this.detectedCountry];
		},
		showMobileExistsAlerts() {
			return this.fullNumber && this.mobileExists;
		},
		errorAria() {
			return this.ariaDescribedby || 'fieldErrors{' + this.id + '}Error';
		},
	},
	watch: {
		fullNumber(newValue) {
			if (!newValue) {
				this.valid = true;
				this.mobileExists = false;
				this.mobileDoesNotExist = false;
				$('.form-actions input').prop('disabled', false);
			}
		},
	},
	created() {
		this.fullNumber = this.value;
	},
	mounted() {
		$(window).on('darkmode:toggle', this.updateDarkModeState);
		this.isDarkMode = isDarkReaderEnabled();

		const input = document.querySelector('#' + this.getId().replace('.', '\\.'));
		if (input) {
			this.iti = intlTelInput(input, {
				initialCountry: this.country || this.detectedCountry,
				preferredCountries: this.preferredCountry,
				separateDialCode: true,
				formatOnDisplay: false,
				autoPlaceholder: 'off',
				utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js', // Public CDN from docs
			});

			setTimeout(() => {
				this.onCountryChange();
			}, 100);
		}
	},
	beforeDestroy() {
		clearTimeout(this.timeout);
	},
	methods: {
		countryDialCode() {
			return this.iti && this.iti.selectedDialCode ? this.iti.selectedDialCode.textContent : '';
		},
		getId() {
			if (this.id) {
				return this.id;
			}

			return this.field.slug + '-' + this.elementId;
		},
		onCountryChange() {
			this.$refs.input.style.paddingLeft = this.paddingLeft() + 'px';
			this.onInput({ target: { value: this.$refs.input.value } });
		},
		isPhoneNumber(event) {
			event = event || window.event;
			const charCode = event.which ? event.which : event.keyCode;
			if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 43) {
				this.$emit('typedNonNumericCharter', this.fullNumber + event.key);
				event.preventDefault();
			} else {
				return true;
			}
		},
		onInput(event) {
			// We need a slight delay, so when the country selection changes, the plugin knows about this in countryDialCode()
			clearTimeout(this.timeout);
			this.timeout = setTimeout(() => {
				let value = (event.target.value + '')
					.trim()
					.replaceAll(/[^ -~]+/g, '') // remove non-printable characters
					.replace('-', ' ');

				// Don't trim the leading zero for problematic countries
				if (!this.phoneWithLeadingZeros.includes(this.countryDialCode())) {
					value = value.replace(/^(0*)/, '');
				}

				// Add country code to the value if it's not present
				if (value && value !== this.countryDialCode() && value[0] !== '+') {
					this.fullNumber = this.countryDialCode() + value;
				} else {
					this.fullNumber = value;
				}

				this.storeValue = this.fullNumber.replace(/\s/g, '');
				this.$emit('input', this.storeValue);
				this.$emit('update:value', this.storeValue);
			}, 200);
		},
		numberWithoutCountryCode() {
			return (this.fullNumber || '').replace(this.countryDialCode(), '');
		},
		paddingLeft() {
			return 58 + this.countryDialCode().length * 8 + (this.isRtl ? 12 : 0);
		},
		validateAndVerify() {
			setTimeout(() => {
				this.validate();

				// Only verify mobile if present and verify prop set to true (registration page)
				if (!!this.fullNumber && this.verify) {
					// Disable register button
					$('.form-actions input').prop('disabled', true);

					this.valid = true;
					this.mobileExists = false;
					this.mobileDoesNotExist = false;

					// Validate mobile
					if (!phoneValidator(this.fullNumber)) {
						this.valid = false;
						return;
					}

					this.fetching = true;

					this.$http.post('/register/verify-mobile', { mobile: this.fullNumber }).then((response) => {
						this.fetching = false;
						this.toggleAlert(response.data);
					});
				}
			}, 300);
		},
		toggleAlert(mobileExists) {
			if (mobileExists) {
				this.mobileExists = true;
			} else {
				this.mobileDoesNotExist = true;
				if (!$('.emailExistsText').is(':visible')) {
					$('.form-actions input').prop('disabled', false);
				}
			}
		},
		updateDarkModeState() {
			this.isDarkMode = isDarkReaderEnabled();
		},
	},
};
</script>

<style scoped>
.iti--separate-dial-code .iti__flag-container {
	left: 0;
	right: auto;
	direction: ltr;
}

.fetchingIcon,
.mobileExistsIcon,
.mobileDoesNotExistIcon {
	float: right;
	margin-right: 10px;
	margin-top: -27px;
	position: relative;
	z-index: 2;
	cursor: default;
}

.mobileInvalid {
	color: red;
	margin-top: 5px;
}

.mobileExistsIcon {
	color: red;
}

.mobileExistsText {
	color: red;
	margin-top: 5px;
}

.mobileDoesNotExistIcon {
	color: green;
}
</style>
